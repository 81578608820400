import { createTheme } from "@mui/material/styles";

const Typography = {
  fontSize: 14,
  htmlFontSize: 14,
  useNextVariants: true,
  h1: {
    fontWeight: 600,
    fontSize: "2rem",
  },
  h2: {
    fontWeight: 600,
    fontSize: "1.38rem",
  },
  h3: {
    fontWeight: 500,
    fontSize: "1.3rem",
  },
  h4: {
    fontWeight: 500,
    fontSize: "1.2rem",
  },
  h5: {
    fontWeight: 400,
    fontSize: "1.2rem",
  },
  h6: {
    fontWeight: 400,
    fontSize: "1rem",
  },
};

// A custom theme for this app
const theme = createTheme({
  typography: Typography,
  palette: {
    primary: {
      main: "#1a0f5e",
    },
    //   secondary: {
    //     main: '#eab21e'
    //   }
  },
});

export default theme;
