import { Storage } from "aws-amplify";

export const removeFileFromS3 = (file) => Storage.remove(file);

export const uploadProfilePictureToS3 = (file) => {
  if (!file) throw new Error("File is required");
  const options = {
    contentType: file.type,
  };
  return Storage.put(`profile/${file.name}`, file, options);
};

export const uploadDocumentToS3 = (file) => {
  if (!file) throw new Error("File is required");
  const options = {
    contentType: file.type,
  };
  return Storage.put(`library/${file.name}`, file, options);
};

export const getFileFromS3 = (key) => {
  if (!key) return null;
  return Storage.get(key);
};
