import React, { useEffect, useState } from "react";
import useNotes from "../../hooks/useNotes";
import Grid from "@mui/material/Grid";
import { CircularProgress } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import Button from "@mui/material/Button";
import { useUserState } from "../../context/user";

function Notes({ processId }) {
  const [{ data, loading }, { getNotes, createNote, updateNote }] = useNotes();
  const [note, setNote] = useState("");
  const user = useUserState();

  useEffect(
    () => {
      if (!processId) return;
      getNotes(processId);
    },
    //eslint-disable-next-line
    [processId]
  );

  useEffect(
    () => {
      if (!data?.content) return;
      setNote(data.content);
    },
    //eslint-disable-next-line
    [data]
  );

  function handleSave() {
    if (data?.id) {
      return updateNote(data.id, { content: note });
    }
    return createNote({
      content: note,
      userId: user.id,
      processId,
    });
  }

  return (
    <Grid container spacing={2}>
      <Note {...{ loading, setNote, note }} />
      <Grid item md={12}>
        <Button
          sx={{ mt: 5, maxHeight: 80 }}
          disabled={loading || !note}
          variant="outlined"
          onClick={handleSave}
        >
          {loading && note ? "Guardando..." : "Guardar Nota"}
        </Button>
      </Grid>
    </Grid>
  );
}

function Note({ setNote, note, loading }) {
  return (
    <Grid
      item
      xs={12}
      container
      sx={{ mt: 1 }}
      spacing={2}
      justifyContent={"center"}
    >
      {loading && <CircularProgress size={60} sx={{ mt: 1 }} />}
      {!loading && (
        <ReactQuill
          tabIndex={1}
          theme="snow"
          value={note}
          onChange={setNote}
          style={{ width: "100%" }}
        />
      )}
    </Grid>
  );
}

export default Notes;
