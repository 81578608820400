import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { dateFormatter } from "../../utils";
import { Paper } from "@mui/material";

function Comment({ comment, user }) {
  if (!comment) return null;

  return (
    <Grid
      item
      md={12}
      container
      component={Paper}
      elevation={1}
      spacing={1}
      sx={{ my: 2, p: 1 }}
    >
      <Grid item md={12}>
        <Box width="100%">
          <Typography align="left">{comment.content}</Typography>
        </Box>
      </Grid>
      <Grid item md={6}>
        <Typography variant="caption" align="left">
          {comment?.user?.name || user?.name}
        </Typography>
      </Grid>
      <Grid item md={6} container justifyContent="flex-end">
        <Typography variant="caption">
          {dateFormatter({ value: comment.createdAt })}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Comment;
