import { API } from "aws-amplify";
import { useCallback, useState } from "react";
import { getFileFromS3 } from "../utils/aws";
import { useAlertDispatch } from "../context/alert";

export default function useUserProfile() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { openAlert } = useAlertDispatch();

  const updateUser = useCallback(async function updateUser(id, props) {
    try {
      setLoading(true);
      const response = await API.put("lawyerapi", `/users/${id}`, {
        body: props,
      });
      setData((prev) => ({ ...prev, ...props }));
      console.log("User Response: ", response);
      openAlert({ variant: "success", message: response.message });
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
    //eslint-disable-next-line
  }, [])

  const createUser = useCallback(async function createUser(props) {
    try {
      setLoading(true);
      const response = await API.post("lawyerapi", `/users`, {
        body: props,
      });
      setData(props);
      console.log("User Response: ", response);
      return response.data;
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getProfileData = useCallback(async function getProfileData(cognitoId) {
    try {
      setLoading(true);
      console.log("cognitoId", cognitoId);
      const response = await API.get(
        "lawyerapi",
        `/users/cognito/${cognitoId}`
      );
      console.log("Profile Data: ", response);
      const { data: profile } = response;
      if (profile?.photo) {
        profile.signedPhoto = await getFileFromS3(profile?.photo);
      }
      setData(profile);
      return profile;
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return [
    { data, loading, error },
    { updateUser, getProfileData, createUser },
  ];
}
