import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { Alert, Box, IconButton, Tooltip, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ProcessesTable from "../../components/table/ProcessesTable";
import useProcesses from "../../hooks/useProcesses";
import LinearProgress from "@mui/material/LinearProgress";
// import { useNavigate } from 'react-router-dom'
import DeleteEntity from "../../components/modal/DeleteEntity";
import { useUserState } from "../../context/user";
import AddProcess from "../../components/modal/AddProcess";
import { useAlertDispatch } from "../../context/alert";
import { dateFormatter } from "../../utils";

export default function ProcessesPage() {
  const user = useUserState();
  // const navigate = useNavigate()
  const [openDelete, setOpenDelete] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [toDelete, setToDelete] = useState(null);
  const { openErrorAlert } = useAlertDispatch();
  const [{ data, loading }, { getProcesses, deleteProcess, createProcess }] =
    useProcesses();

  // const go2 = path => () => navigate(path)

  function handleDelete(id) {
    return () => {
      const found = data.find((p) => p.id === id);
      setToDelete(found);
      setOpenDelete(true);
    };
  }

  function handleOpenAdd() {
    setOpenAdd(true);
  }

  function handleClose() {
    setOpenDelete(false);
    setOpenAdd(false);
  }

  function handleView(id) {
    return () => window.open(`/processes/${id}`, "_blank");
  }

  async function handleConfirmDelete() {
    console.log("toDelete", toDelete);
    await deleteProcess(toDelete.id);
    setToDelete(null);
    handleClose();
  }

  async function handleConfirmAdd(caseNumber, files) {
    let toCreate = null;

    try {
      if ((files || []).length) {
        const [file] = files;
        const results = await parseCsv(file);
        console.log("results", results);
        if (results.errors.length) {
          throw new Error(
            "Se encontró errores en el CSV, por favor revisar que el formato sea valido"
          );
        }
        toCreate = results.data.map((item) => {
          if (item.radicado.length === 23) {
            return {
              caseNumber: item.radicado,
              userId: user.id,
            };
          } else throw new Error("Cada radicado debe tener 23 caracteres");
        });
      } else {
        toCreate = { caseNumber, userId: user.id };
      }
      console.log("toCreate", toCreate);
      await createProcess(toCreate);
      handleClose();
    } catch (error) {
      openErrorAlert(error.message);
    }
  }

  useEffect(() => {
    if (!user) return;
    getProcesses({ userId: user.id, performances: false });
    // eslint-disable-next-line
  }, [user])

  const overTheLimit = user.planLimit <= data.length;
  const planExpired = new Date(user.planEndDate) < new Date();

  return (
    <div>
      {loading && <LinearProgress />}
      <PlanAlert
        user={user}
        data={data}
        overTheLimit={overTheLimit}
        planExpired={planExpired}
      />
      <Box display="flex" width="100%" alignItems="center" mb={4}>
        <Typography variant="h1">Mis Procesos Judiciales</Typography>
        <Tooltip title="Agregar Proceso">
          <IconButton
            size="large"
            color="primary"
            disabled={overTheLimit || planExpired}
            aria-label="add-process"
            onClick={handleOpenAdd}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <ProcessesTable
        data={data}
        handleView={handleView}
        handleDelete={handleDelete}
      />
      <DeleteEntity
        open={openDelete}
        entity="proceso"
        id={toDelete?.caseNumber}
        handleClose={handleClose}
        handleConfirm={handleConfirmDelete}
      />
      <AddProcess
        open={openAdd}
        loading={loading}
        handleClose={handleClose}
        handleConfirm={handleConfirmAdd}
      />
    </div>
  );
}

function PlanAlert({ user, data, planExpired, overTheLimit }) {
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    const storedValue = sessionStorage.getItem("showAlert");
    setShowAlert(storedValue !== "false");
  }, []);

  function onClose() {
    setShowAlert(false);
    sessionStorage.setItem("showAlert", "false");
  }

  if (!showAlert) return null;

  if (planExpired) {
    return (
      <Alert severity="warning" onClose={onClose}>
        Tu plan ha vencido. Ve a tu perfil y renuévalo para seguir disfrutando
        de Lawyers.
      </Alert>
    );
  }
  if (overTheLimit) {
    return (
      <Alert severity="warning" onClose={onClose}>
        Estas sobre el limite de procesos de tu plan.
      </Alert>
    );
  }
  return (
    <Alert severity="info" onClose={onClose}>
      Tienes un plan {user.free ? "gratis" : ""} de {user.planLimit} procesos,
      hasta el momento tines {data.length}.{" "}
      {user.planEndDate ? "El plan vence el" : ""}{" "}
      {user.planEndDate ? dateFormatter({ value: user.planEndDate }) : ""}.
    </Alert>
  );
}

const parseCsv = (file) => {
  return new Promise(function (complete, error) {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: true,
      transformHeader: (h) => h.trim(),
      error,
      complete,
    });
  });
};
