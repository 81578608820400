import {
  Box,
  IconButton,
  LinearProgress,
  Paper,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { memo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PerformancesTable from "../../components/table/PerformancesTable";
import useProcesses from "../../hooks/useProcesses";
import AddToDriveIcon from "@mui/icons-material/AddToDrive";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Notes from "./Notes";

function ProcessDetailPage() {
  const [{ data, loading }, { getProcessDetail, updateProcess }] =
    useProcesses();
  const params = useParams();
  const navigate = useNavigate();
  const go2 = (path) => () => navigate(path);
  useEffect(() => {
    if (!params.id) return;
    getProcessDetail(params.id);
    // eslint-disable-next-line
  }, [params.id]);

  function handleView(id) {
    return go2(`/library/${id}`);
  }

  function handleSaveLink(driveFolderLink) {
    if (!driveFolderLink) return null;
    return updateProcess(params.id, { driveFolderLink });
  }

  const showLoader = !data.length && loading;
  return (
    <div>
      {showLoader && <LinearProgress />}
      {data && <Content {...{ data, loading, handleView, handleSaveLink }} />}
    </div>
  );
}

function Content({ data, loading, handleView, handleSaveLink }) {
  console.log("Content  data", data);
  const [value, setValue] = React.useState(0);
  const [link, setLink] = React.useState("");
  const handleChange = (event, newValue) => setValue(newValue);
  const handleChangeLink = (e) => setLink(e.target.value);

  const source = data.driveFolderLink;
  useEffect(() => {
    if (source) {
      setLink(source);
    }
  }, [source]);
  const [plaintiff, defendant] = (data?.proceduralSubjects || "").split("|");

  return (
    <Box>
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        mb={4}
        flexWrap={"wrap"}
      >
        <Box display="flex" flexDirection={"column"}>
          <Typography variant="h1" paragraph>
            # {data.caseNumber}
          </Typography>{" "}
          {plaintiff && (
            <Typography variant="subtitle2">{plaintiff}</Typography>
          )}
          {defendant && (
            <Typography variant="subtitle2">{defendant}</Typography>
          )}
        </Box>
        <TextField
          sx={{ mx: 2, maxWidth: 620 }}
          name={"link"}
          fullWidth
          disabled={loading}
          value={link || ""}
          InputProps={{
            startAdornment: (
              <Tooltip title="Abrir">
                <IconButton
                  size="small"
                  color="primary"
                  disabled={!source || loading}
                  aria-label="add-drive"
                  onClick={() => window.open(link, "_blank")}
                >
                  <OpenInNewIcon fontSize={"small"} />
                </IconButton>
              </Tooltip>
            ),
            endAdornment: (
              <Tooltip title="Guardar">
                <IconButton
                  size="small"
                  color="primary"
                  disabled={!link || loading || source === link}
                  aria-label="add-drive"
                  onClick={() => handleSaveLink(link)}
                >
                  <AddToDriveIcon fontSize={"small"} />
                </IconButton>
              </Tooltip>
            ),
          }}
          onChange={handleChangeLink}
          helperText={"Copia y pega el enlance de Google Drive"}
        />
      </Box>

      <Paper>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Actuaciones" {...a11yProps(0)} />
          <Tab label="Notas" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <PerformancesTable
            data={data.performances || []}
            handleView={handleView}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Notes processId={data.id} />
        </TabPanel>
      </Paper>
    </Box>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default memo(ProcessDetailPage);
