import React, { useEffect } from "react";
import { Amplify, Auth, Hub, I18n } from "aws-amplify";
import {
  Authenticator,
  Image,
  Text,
  useTheme,
  View,
} from "@aws-amplify/ui-react";
import { RouterProvider } from "react-router-dom";
import { Alert } from "./components/alert";
import router from "./router";
import awsExports from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";
import { useUser } from "./context/user";
import useUserProfile from "./hooks/useUserProfile";
import { Box, CircularProgress, Typography } from "@mui/material";
import Logo from "./assets/logo.png";
import { esDict } from "./i18n";

I18n.putVocabularies({
  es: esDict,
});

I18n.setLanguage("es");

Amplify.configure(awsExports);

function App() {
  const [user, { login }] = useUser();
  const [{ loading }, { getProfileData, createUser }] = useUserProfile();

  async function handleSignUp(formData) {
    console.log("Form Data:", formData);
    let { username, password, attributes } = formData;
    username = username.toLowerCase();
    attributes.email = attributes.email.toLowerCase();
    const result = await Auth.signUp({
      username,
      password,
      attributes,
      autoSignIn: {
        enabled: true,
      },
    });
    console.log("Result >>", result);
    return result;
  }

  async function handleSignIn({ username, password }) {
    const result = await Auth.signIn({
      username,
      password,
    });
    console.log("SignIn Result: ", result);
    const profile = await getProfileData(result?.username);
    await login(profile);
    return result;
  }

  function listenToAutoSignInEvent() {
    Hub.listen("auth", async ({ payload }) => {
      const { event } = payload;
      if (event === "autoSignIn") {
        const user = payload.data;
        console.log("Hub User", user);
        const created = await createUser({
          cognitoId: user.attributes.sub,
          email: user.attributes.email,
        });
        await login(created);
      } else if (event === "autoSignIn_failure") {
        // redirect to sign in page
        console.error("autoSignIn_failure", payload);
      }
    });
  }

  useEffect(() => {
    listenToAutoSignInEvent();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    (async () => {
      const info = await Auth.currentUserInfo();
      console.log("Current Auth Info:", info);
      if (!user && info) {
        const profile = await getProfileData(info.username);
        await login(profile);
      }
    })();
    // eslint-disable-next-line
  }, [])

  return (
    <div className="App">
      <Alert />
      {!user && (
        <Box
          width="100%"
          padding="16px"
          bgcolor="lightblue"
          textAlign="center"
          boxShadow={3}
          mb={2}
        >
          <Typography variant="h5" gutterBottom>
            🎉 ¡Prueba gratuita de 7 días!
          </Typography>
          <Typography variant="body1" gutterBottom>
            Regístrate hoy y disfruta de 7 días de acceso a todas nuestras
            funciones premium.
          </Typography>
        </Box>
      )}
      <Authenticator
        components={{
          Header() {
            const { tokens } = useTheme();
            return (
              <View textAlign="center" padding={tokens.space.large}>
                <Image
                  alt="Lawyers logo"
                  objectFit="initial"
                  objectPosition="50% 50%"
                  backgroundColor="initial"
                  height="auto"
                  width="120px"
                  opacity="100%"
                  src={Logo}
                />
              </View>
            );
          },

          Footer() {
            const { tokens } = useTheme();

            return (
              <View textAlign="center" padding={tokens.space.large}>
                <Text color={tokens.colors.neutral[80]}>
                  &copy; {new Date().getFullYear()}, Lawyers. Todos los derechos
                  reservados.
                </Text>
              </View>
            );
          },
        }}
        services={{
          handleSignIn,
          handleSignUp,
        }}
      >
        {(authenticatorProps) => (
          <>
            {loading && (
              <Box
                width="100%"
                height="80vh"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress size={80} />
                <Typography sx={{ p: 4 }}>Iniciando plataforma...</Typography>
              </Box>
            )}
            {!!user && !loading && (
              <RouterProvider router={router(authenticatorProps)} />
            )}
          </>
        )}
      </Authenticator>
    </div>
  );
}

export default App;
