import { API, Storage } from "aws-amplify";
import { useCallback, useState } from "react";
import { useAlertDispatch } from "../context/alert";
import { useUser } from "../context/user";
import { getFileFromS3 } from "../utils/aws";

const RAMA_URL = "www.ramajudicial.gov.co";

export default function useLibrary() {
  const [data, setData] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [error, setError] = useState(null);
  const { openAlert, openErrorAlert } = useAlertDispatch();
  const [user, { login }] = useUser();

  const updateDocument = useCallback(async function updateDocument(id, props) {
    try {
      setLoading(true);
      const response = await API.put("lawyerapi", `/documents/${id}`, {
        body: props,
      });
      setData((prev) => ({ ...prev, ...props }));
      console.log("Document Response: ", response);
      openAlert({ message: response.message, variant: "success" });
    } catch (error) {
      console.error(error);
      setError(error);
      openErrorAlert("Algo salio mal");
    } finally {
      setLoading(false);
    }
    //eslint-disable-next-line
  }, [])

  const deleteDocument = useCallback(
    async function deleteDocument(id, key) {
      try {
        setLoading(true);
        const found = data.findIndex((d) => d.id === id);
        if (found === -1) throw new Error("Document not found");
        const newData = data.slice();
        newData.splice(found, 1);
        setData(newData);
        const response = await API.del("lawyerapi", `/documents/${id}`);
        console.log("Document Response: ", response);
        const s3Response = await Storage.remove(key);
        console.log("S3 Response: ", s3Response);
      } catch (error) {
        console.error(error);
        setError(error);
        openErrorAlert("Algo salio mal");
      } finally {
        setLoading(false);
      }
    },
    //eslint-disable-next-line
    [data]
  );

  const addLike = (list = [], item) => list.concat(item);
  const removeLike = (list = [], item) => {
    let shadow = list.slice();
    shadow.splice(
      list.findIndex((i) => i.id === item.id),
      1
    );
    return shadow;
  };

  const likeDocument = useCallback(
    async function likeDocument(id, liked) {
      try {
        setLoading(true);

        if (!liked) {
          const response = await API.post(
            "lawyerapi",
            `/documents/${id}/like`,
            {
              body: { userId: user.id, documentId: id },
            }
          );
          const { data: like, message } = response;
          console.log("Document Response: ", response);
          openAlert({ variant: "success", message });
          const newLike = { ...like, user };
          setData((prev) =>
            prev.map((doc) => {
              const likedDoc = doc.id === id;
              if (!likedDoc) return doc;
              return {
                ...doc,
                _count: { ...doc._count, likes: doc._count.likes + 1 },
              };
            })
          );
          login({ ...user, likes: addLike(user?.likes, newLike) });
        } else if (liked) {
          // Unlike
          const response = await API.post(
            "lawyerapi",
            `/documents/${id}/like`,
            {
              body: { dislike: liked.id },
            }
          );
          const { message } = response;
          console.log("Document Response: ", response);
          openAlert({ variant: "success", message });
          setData((prev) =>
            prev.map((doc) => {
              const likedDoc = doc.id === id;
              if (!likedDoc) return doc;
              return {
                ...doc,
                _count: { ...doc._count, likes: doc._count.likes - 1 },
              };
            })
          );
          login({ ...user, likes: removeLike(user?.likes, liked) });
        }
      } catch (error) {
        console.error(error);
        setError(error);
        openErrorAlert("Algo salio mal");
      } finally {
        setLoading(false);
      }
    },
    //eslint-disable-next-line
    [data, user]
  );

  const commentDocument = useCallback(
    async function commentDocument(id, props) {
      try {
        setLoading(true);
        const response = await API.post(
          "lawyerapi",
          `/documents/${id}/comment`,
          {
            body: props,
          }
        );
        const { data: comment, message } = response;
        setData((prev) => ({
          ...prev,
          comments: (prev?.comments || []).concat(comment),
        }));
        console.log("Document Response: ", response);
        openAlert({ variant: "success", message });
      } catch (error) {
        console.error(error);
        setError(error);
        openErrorAlert("Algo salio mal");
      } finally {
        setLoading(false);
      }
    },
    //eslint-disable-next-line
    []
  );

  const deleteComment = useCallback(
    async function deleteDocument(id, key) {
      try {
        setLoading(true);
        const found = data.findIndex((d) => d.id === id);
        if (found === -1) throw new Error("Document not found");
        const newData = data.slice();
        newData.splice(found, 1);
        setData(newData);
        const response = await API.del(
          "lawyerapi",
          `/documents/comments/${id}`
        );
        console.log("Document Response: ", response);
        const s3Response = await Storage.remove(key);
        console.log("S3 Response: ", s3Response);
      } catch (error) {
        console.error(error);
        setError(error);
        openErrorAlert("Algo salio mal");
      } finally {
        setLoading(false);
      }
    },
    //eslint-disable-next-line
    [data]
  );

  const createDocument = useCallback(async function createDocument(props) {
    try {
      setLoading(true);
      const response = await API.post("lawyerapi", `/documents`, {
        body: props,
      });
      const { data: doc, message } = response;
      setData((prev) => prev.concat(doc));
      console.log("Document Response: ", response);
      openAlert({ variant: "success", message });
    } catch (error) {
      console.error(error);
      setError(error);
      openErrorAlert("Algo salio mal");
    } finally {
      setLoading(false);
    }
    //eslint-disable-next-line
  }, [])

  const getDocuments = useCallback(async function getDocuments(query) {
    try {
      setLoadingCategories(true);
      const response = await API.get("lawyerapi", `/documents`, {
        queryStringParameters: query,
      });
      setData(response?.data || []);
      console.log("documents Response: ", response?.data);
    } catch (error) {
      console.error(error);
      setError(error);
      openErrorAlert("Algo salio mal");
    } finally {
      setLoadingCategories(false);
    }
    //eslint-disable-next-line
  }, [])

  const getCategories = useCallback(async function getCategories(query) {
    try {
      setLoading(true);
      const response = await API.get("lawyerapi", `/categories`, {
        queryStringParameters: query,
      });
      setCategories(response?.data || []);
      console.log("Categories Response: ", response?.data);
    } catch (error) {
      setError(error);
      openErrorAlert("Algo salio mal");
    } finally {
      setLoading(false);
    }
    //eslint-disable-next-line
  }, [])

  const getDocumentDetail = useCallback(async function getDocumentDetail(id) {
    try {
      setLoading(true);
      const response = await API.get("lawyerapi", `/documents/${id}`);
      const doc = response.data;
      const isFromRama = (doc?.path || "").includes(RAMA_URL);
      if (doc?.path && !isFromRama) {
        doc.signed = await getFileFromS3(doc?.path);
      }
      if (isFromRama) doc.signed = doc.path;

      setData(doc);
      console.log("documents Response: ", response);
    } catch (error) {
      console.error(error);
      setError(error);
      openErrorAlert("Algo salio mal");
    } finally {
      setLoading(false);
    }
    //eslint-disable-next-line
  }, [])

  const downloadDocument = useCallback(
    async function downloadDocument(fileName) {
      try {
        setLoading(true);
        const isFromRama = (fileName || "").includes(RAMA_URL);
        const fileUrl = isFromRama ? fileName : await getFileFromS3(fileName);
        const request = new Request(fileUrl);
        const result = await fetch(request);
        const data = await result.blob();
        const url = window.URL.createObjectURL(data);
        createDownloadLink({ href: url, name: fileName });
      } catch (error) {
        console.error(error);
        setError(error);
        openErrorAlert("Algo salio mal");
      } finally {
        setLoading(false);
      }
    },
    //eslint-disable-next-line
    []
  );

  return [
    { data, categories, loading, error, loadingCategories },
    {
      updateDocument,
      likeDocument,
      deleteComment,
      commentDocument,
      createDocument,
      getDocuments,
      getCategories,
      downloadDocument,
      getDocumentDetail,
      deleteDocument,
    },
  ];
}

export function createDownloadLink({ href, name }) {
  let a = document.createElement("a");
  a.href = href;
  a.target = "_blank";
  // a.download = name
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
