import { createBrowserRouter } from "react-router-dom";
import AppLayout from "./pages/home/AppLayout";
import ErrorPage from "./pages/error/ErrorPage";
import ProcessesPage from "./pages/processes/ProcessesPage";
import ProcessDetailPage from "./pages/processes/ProcessDetail";
import ProfilePage from "./pages/auth/Profile";
import LibraryPage from "./pages/library/LibraryPage";
import MeetingPage from "./pages/meeting/MeetingPage";
import DocumentDetailPage from "./pages/library/DocumentDetail";
import PaymentPage from "./pages/payment/PaymentPage";

const router = (authenticatorProps) =>
  createBrowserRouter([
    {
      element: <AppLayout {...authenticatorProps} />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <ProcessesPage {...authenticatorProps} />,
        },
        {
          path: "/profile",
          element: <ProfilePage {...authenticatorProps} />,
        },
        {
          path: "/library",
          element: <LibraryPage {...authenticatorProps} />,
        },
        {
          path: "/meeting",
          element: <MeetingPage {...authenticatorProps} />,
        },
        {
          path: "/payments",
          element: <PaymentPage {...authenticatorProps} />,
        },
        {
          path: "/processes/:id",
          element: <ProcessDetailPage {...authenticatorProps} />,
        },
        {
          path: "/library/:id",
          element: <DocumentDetailPage {...authenticatorProps} />,
        },
      ],
    },
  ]);

export default router;
