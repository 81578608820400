import {
  Avatar,
  Badge,
  Box,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import useLibrary from "../../hooks/useLibrary";
import AddIcon from "@mui/icons-material/Add";
import AddDocument from "../../components/modal/AddDocument";
import { uploadDocumentToS3 } from "../../utils/aws";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import { useUserState } from "../../context/user";
import DeleteEntity from "../../components/modal/DeleteEntity";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import MessageIcon from "@mui/icons-material/Message";
import { getFileExtension, isLiked } from "../../utils";

const LIMIT = 100;

export default function LibraryPage() {
  const [
    { data, loading, categories, loadingCategories },
    {
      getDocuments,
      createDocument,
      getCategories,
      likeDocument,
      downloadDocument,
      deleteDocument,
    },
  ] = useLibrary();
  const [state, setState] = useState({});
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [toDelete, setToDelete] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [search, setSearch] = useState("");
  const user = useUserState();

  const entries = Object.entries(state);
  const valid = entries.filter(([, value]) => value);

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setState({
      ...state,
      [name]: checked,
    });
  };

  function getCategoryQuery() {
    const query = valid.length
      ? valid.reduce(
          (acc, [key]) => ({ categoryId: [...acc.categoryId, key] }),
          {
            categoryId: [],
          }
        )
      : null;
    return query;
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleChangeSearch(event) {
    setSearch(event.target.value);
  }

  function handleClose() {
    setOpen(false);
    setOpenDelete(false);
  }

  function handleSearch() {
    const query = getCategoryQuery();
    return getDocuments({
      library: true,
      take: LIMIT,
      searchString: search,
      ...query,
    });
  }

  function handleEnter(e) {
    if (e.keyCode !== 13) return;
    e.preventDefault();
    return handleSearch();
  }

  function handleDelete(id) {
    return () => {
      const found = data.find((p) => p.id === id);
      setToDelete(found);
      setOpenDelete(true);
    };
  }

  function handleLike(id, bool) {
    return () => {
      return likeDocument(id, bool);
    };
  }

  async function handleConfirm({ name, files, categoryId }) {
    const [file] = files;
    console.log("Confirm: ", { name, file, categoryId });
    if (!file || !categoryId) return;
    setUploading(true);
    const result = await uploadDocumentToS3(file);
    await createDocument({
      name,
      categoryId,
      library: true,
      path: result.key,
      createdById: user.id,
    });
    setUploading(false);
  }

  async function handleConfirmDelete() {
    console.log("toDelete", toDelete);
    await deleteDocument(toDelete.id, toDelete.path);
    handleClose();
  }

  useEffect(() => {
    const query = getCategoryQuery();
    getDocuments({ library: true, take: LIMIT, ...query });
    // eslint-disable-next-line
  }, [state])

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line
  }, [])

  return (
    <Grid container alignContent="center" alignItems="center">
      <AddDocument
        open={open}
        uploading={uploading}
        categories={categories}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
      <DeleteEntity
        open={openDelete}
        entity="documento"
        id={toDelete?.name}
        loading={loading}
        handleClose={handleClose}
        handleConfirm={handleConfirmDelete}
      />
      <Grid item md={12}>
        <Box display="flex" width="100%" alignItems="center">
          <Typography variant="h1">Biblioteca Jurídica</Typography>
          <Tooltip title="Agregar Documento">
            <IconButton
              size="large"
              color="primary"
              aria-label="add-document"
              onClick={handleOpen}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper>
          <StyledInputBase
            autoFocus
            fullWidth
            value={search}
            onKeyUp={handleEnter}
            onChange={handleChangeSearch}
            endAdornment={
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                disabled={loading}
                onClick={handleSearch}
              >
                <SearchIcon />
              </IconButton>
            }
            placeholder="Buscar documento..."
            inputProps={{ "aria-label": "search " }}
          />
        </Paper>
      </Grid>
      <Grid item sm={12} md={6}>
        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
          <FormLabel component="legend">Categorias</FormLabel>
          {!categories.length && loadingCategories && <LinearProgress />}
          {!!categories.length && (
            <FormGroup row>
              {categories.map((category) => (
                <FormControlLabel
                  key={category.id}
                  control={
                    <Checkbox
                      checked={state[category.id] || false}
                      onChange={handleChange}
                      name={String(category.id)}
                    />
                  }
                  label={category.name}
                />
              ))}
            </FormGroup>
          )}
        </FormControl>
      </Grid>
      <Grid item md={12}>
        {(loading || loadingCategories) && <LinearProgress />}
        <Box width="100%" display="flex" justifyContent="flex-start">
          {!!valid.length && (
            <Typography variant="caption">
              {data.length} {data.length === 1 ? "documento" : "documentos"}{" "}
              {data.length === 1 ? "encontrado" : "encontrados"}
            </Typography>
          )}
        </Box>
        <Content
          data={data}
          user={user}
          loading={loading}
          download={downloadDocument}
          handleLike={handleLike}
          handleDelete={handleDelete}
        />
      </Grid>
    </Grid>
  );
}

function Content({ data, user, loading, download, handleDelete, handleLike }) {
  const navigate = useNavigate();
  const go2 = (path) => () => navigate(path);
  const theme = useTheme();

  return (
    <Grid sx={{ mt: 2 }} container spacing={3}>
      {(data || []).map((item) => {
        const liked = isLiked(user, item);
        return (
          <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
            <Card>
              <CardHeader
                title={item.name}
                subheader={item.category?.name}
                avatar={
                  <Avatar
                    aria-label="recipe"
                    sx={{ bgcolor: theme.palette.secondary.main }}
                  >
                    <Typography variant="caption">
                      {getFileExtension(item.path)}
                    </Typography>
                  </Avatar>
                }
              />
              <CardActions>
                {item?.createdById === user.id && (
                  <Tooltip title="Borrar">
                    <IconButton
                      size="small"
                      aria-label="delete-document"
                      disabled={loading}
                      onClick={handleDelete(item.id)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Descargar">
                  <IconButton
                    size="small"
                    aria-label="download-document"
                    disabled={loading}
                    onClick={() => download(item.path)}
                  >
                    <DownloadIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Ver">
                  <IconButton
                    size="small"
                    aria-label="view-document"
                    disabled={loading}
                    onClick={go2(`/library/${item.id}`)}
                  >
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Comentarios">
                  <IconButton
                    size="small"
                    aria-label="view-document"
                    disabled={loading}
                    onClick={go2(`/library/${item.id}`)}
                  >
                    <Badge
                      badgeContent={item?._count?.comments}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                    >
                      <MessageIcon color="inherit" fontSize="small" />
                    </Badge>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Me Gusta">
                  <IconButton
                    size="small"
                    aria-label="like-document"
                    disabled={loading}
                    onClick={handleLike(item.id, liked)}
                  >
                    <Badge
                      badgeContent={item?._count?.likes}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                    >
                      <ThumbUpIcon
                        fontSize="small"
                        color={liked ? "primary" : "inherit"}
                      />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </CardActions>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));
