import { Box, Container, Divider, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import usePayments from "../../hooks/usePayments";
import { PLANS, WOMPI_ENDPOINT } from "../../utils";
import { useUserState } from "../../context/user";
import { useAlertDispatch } from "../../context/alert";

export default function PaymentPage() {
  const search = useLocation().search;
  const user = useUserState();
  const { openErrorAlert } = useAlertDispatch;
  const transactionId = new URLSearchParams(search).get("id");
  const [{ data }, { createPayment }] = usePayments();

  async function getPaymentResponse() {
    const res = await fetch(WOMPI_ENDPOINT + "/" + transactionId);
    const { data } = await res.json();
    console.log("data", data);
    return data;
  }

  async function init() {
    const {
      status,
      reference,
      payment_method_type: method,
      amount_in_cents = 100,
    } = await getPaymentResponse();
    const amount = amount_in_cents / 100;
    const userId = user.id;
    const plan = PLANS.find((p) => p.price === amount);
    if (!plan) throw new Error("No plan found for amount:" + amount);
    await createPayment({
      plan,
      transactionId,
      amount,
      method,
      userId,
      status,
      reference,
    });
  }

  useEffect(() => {
    if (!transactionId) return;
    try {
      init();
    } catch (error) {
      console.error(error);
      openErrorAlert("Algo salió mal iniciando el pago.");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId]);

  return (
    <Container>
      <Typography variant="h2" paragraph>
        Información de pago
      </Typography>
      <Divider />
      {!transactionId && (
        <Typography>No hay ID de transacción valido</Typography>
      )}
      {data && <PaymentInfo data={data} />}
    </Container>
  );
}

function PaymentInfo({ data }) {
  return (
    <Box display="flex" flexDirection="column" my={2}>
      <Typography gutterBottom>
        <strong>ID Transacción:</strong> {data?.transactionId}
      </Typography>
      <Typography gutterBottom>
        <strong>Cantidad:</strong> {data?.amount}
      </Typography>
      <Typography gutterBottom>
        <strong>Método:</strong> {data?.method}
      </Typography>
      <Typography gutterBottom>
        <strong>Estado:</strong> {data?.status}
      </Typography>
      <Typography gutterBottom>
        <strong>Referencia:</strong> {data?.reference}
      </Typography>
    </Box>
  );
}

// const response = {
//   data: {
//     id: '116485-1674129545-31997',
//     created_at: '2023-01-19T11:59:05.332Z',
//     amount_in_cents: 30000000,
//     reference: 'LW002901',
//     currency: 'COP',
//     payment_method_type: 'BANCOLOMBIA_TRANSFER',
//     payment_method: {
//       type: 'BANCOLOMBIA_TRANSFER',
//       extra: {
//         async_payment_url:
//           'https://sandbox.wompi.co/v1/payment_methods/redirect/bancolombia_transfer?transferCode=2hOmmC8NrtYlzLRI-approved',
//         external_identifier: '2hOmmC8NrtYlzLRI-approved'
//       },
//       user_type: 'PERSON',
//       sandbox_status: 'APPROVED',
//       payment_description: 'Pago a FACTORING ABOGADOS, ref: LW002901'
//     },
//     redirect_url: 'http://localhost:3000/payments',
//     status: 'APPROVED',
//     status_message: null,
//     merchant: {
//       name: 'FACTORING ABOGADOS',
//       legal_name: 'FACTORING ABOGADOS S.A.S.',
//       contact_name: 'JUAN FERNANDO GOMEZ TABARES',
//       phone_number: '+573006539453',
//       logo_url: null,
//       legal_id_type: 'NIT',
//       email: 'directorcartera@factoringabogados.com',
//       legal_id: '901488360-1'
//     },
//     taxes: []
//   },
//   meta: {}
// }
