import { Flex } from "@aws-amplify/ui-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Slide from "@mui/material/Slide";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import useResponsive from "../../hooks/useResponsive";
import GavelIcon from "@mui/icons-material/Gavel";
// import PhoneIcon from '@mui/icons-material/Phone'
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PersonIcon from "@mui/icons-material/Person";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";

import "./styles.css";

export default function Header(props) {
  const navigate = useNavigate();
  const go2 = (path) => () => navigate(path);
  const { isMobile } = useResponsive();

  return (
    <>
      <HideOnScroll {...props}>
        <AppBar>
          <Toolbar>
            <Flex width="100%" justifyContent={isMobile ? "center" : ""}>
              <Button
                variant="text"
                color="inherit"
                onClick={go2("/")}
                startIcon={<GavelIcon />}
              >
                {isMobile ? "" : "Procesos"}
              </Button>
              <Button
                variant="text"
                color="inherit"
                onClick={go2("/library")}
                startIcon={<LocalLibraryIcon />}
              >
                {isMobile ? "" : "Biblioteca"}
              </Button>
              {/* <Button
                variant='text'
                color="inherit"
                onClick={go2('/meeting')}
                startIcon={<PhoneIcon />}
              >
                {isMobile ? '' : 'Llamadas'}
              </Button> */}
              <Button
                variant="text"
                color="inherit"
                onClick={go2("/profile")}
                startIcon={<PersonIcon />}
              >
                {isMobile ? "" : "Perfil"}
              </Button>
              <Button
                variant="text"
                color="inherit"
                onClick={props.signOut}
                startIcon={<ExitToAppIcon />}
              >
                {isMobile ? "" : "Cerrar Sesión"}
              </Button>
            </Flex>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </>
  );
}

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
