import { DropzoneArea } from "react-mui-dropzone";

const FILES_LIMIT = 1;
const MAX_FILE_SIZE = 150e6; //150mb

function Upload(props) {
  const { item, handleChange, uploading, ...fieldProps } = props;
  return (
    <DropzoneArea
      filesLimit={FILES_LIMIT}
      maxFileSize={MAX_FILE_SIZE}
      showPreviews={false}
      showFileNames
      showPreviewsInDropzone
      dropzoneText={"Arrastra o selecciona un archivo para agregarlo"}
      getFileAddedMessage={() => "Archivo agregado"}
      getFileLimitExceedMessage={() => "El archivo excede el tamañano maximo"}
      getFileRemovedMessage={() => "Archivo removido"}
      onChange={(value) => {
        const event = { target: { name: item.name, value } };
        return handleChange(event);
      }}
      {...fieldProps}
    />
  );
}

export default Upload;
