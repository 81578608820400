import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";
import FileViewer from "react-file-viewer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import useLibrary from "../../hooks/useLibrary";
import { dateFormatter, getFileExtension } from "../../utils";
import Comment from "../../components/comment";
import { useUserState } from "../../context/user";
import UpdateDocument from "../../components/modal/UpdateDocument";

// const PERFORMANCE_CATEGORY = 5;

export default function DocumentDetailPage() {
  const params = useParams();
  const user = useUserState();
  const [open, setOpen] = useState(false);
  const [
    { data, loading },
    { getDocumentDetail, downloadDocument, updateDocument, commentDocument },
  ] = useLibrary();

  function handleComment(props) {
    return commentDocument(data.id, props);
  }

  function handleDownload() {
    return downloadDocument(data.path);
  }

  function handleShowInLibrary(e) {
    const { checked } = e.target;
    if (!checked) {
      return updateDocument(data.id, { library: false });
    } else {
      setOpen(true);
    }
  }

  async function handleConfirmLibrary(name) {
    if (!name) return;
    await updateDocument(data.id, { name, library: true });
    handleClose();
  }

  function handleClose() {
    setOpen(false);
  }

  useEffect(() => {
    getDocumentDetail(params.id);
    // eslint-disable-next-line
  }, []);

  console.log("data :>> ", data);
  return (
    <Box>
      {loading && <LinearProgress />}
      {data && (
        <Content
          data={data}
          open={open}
          user={user}
          loading={loading}
          handleDownload={handleDownload}
          handleClose={handleClose}
          handleComment={handleComment}
          handleConfirmLibrary={handleConfirmLibrary}
          handleShowInLibrary={handleShowInLibrary}
        />
      )}
    </Box>
  );
}

function Content({
  user,
  data,
  open,
  loading,
  handleClose,
  handleDownload,
  handleComment,
  handleConfirmLibrary,
  handleShowInLibrary,
}) {
  const navigate = useNavigate();
  const [comment, setComment] = useState("");

  const goBack = () => navigate(-1);

  const isCreator =
    user?.id === data?.createdById ||
    user?.id === data?.performance?.process?.userId;

  return (
    <Grid container spacing={2}>
      <UpdateDocument
        open={open}
        document={data}
        loading={loading}
        handleConfirm={handleConfirmLibrary}
        handleClose={handleClose}
      />
      <Grid item md={12} container sx={{ mb: 2 }} alignItems="center">
        <IconButton size="large">
          <ArrowBackIcon onClick={goBack} />
        </IconButton>
        <Typography variant="h1">{data?.name || "Actuación"}</Typography>
        <Tooltip title="Descargar">
          <IconButton
            sx={{ mr: 4 }}
            aria-label="download-document"
            onClick={handleDownload}
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>
        {isCreator && (
          <FormGroup sx={{ justifySelf: "flex-end" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={!!data.library}
                  onChange={handleShowInLibrary}
                />
              }
              label="Mostrar en Biblioteca Jurídica"
            />
          </FormGroup>
        )}
      </Grid>
      {isCreator && data?.performance && (
        <Grid item md={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <LinkRouter
              underline="hover"
              color="inherit"
              to={`/processes/${data.performance?.process?.id}`}
            >
              {data.performance?.process?.caseNumber}
            </LinkRouter>
            <Typography color="text.primary">
              {data.performance?.title}
            </Typography>
          </Breadcrumbs>
        </Grid>
      )}
      {data.createdBy && (
        <Grid item md={12}>
          <Typography variant="subtitle1" paragraph>
            Subido por <strong>{data.createdBy?.name}</strong> el{" "}
            {dateFormatter({ value: data.createdAt })}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Typography variant="h2" paragraph sx={{ mb: 3 }}>
          Vista Previa
        </Typography>
        <Box sx={{ height: 800 }}>
          {!!data.path && (
            <FileViewer
              fileType={getFileExtension(data.path)}
              filePath={data.signed}
              // errorComponent={CustomErrorComponent}
              onError={console.error}
            />
          )}
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Typography variant="h2" paragraph sx={{ mb: 3 }}>
          Comentarios
        </Typography>
        <TextField
          disabled={loading}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          id="outlined-multiline-flexible"
          label="Nuevo Comentario"
          fullWidth
          multiline
          rows={4}
        />
        <Box display="flex" justifyContent="flex-end" py={2}>
          <Button
            disabled={loading}
            variant="outlined"
            onClick={async () => {
              await handleComment({
                content: comment,
                userId: user.id,
                documentId: data.id,
              });
              setComment("");
            }}
          >
            {loading ? "Comentando..." : "Comentar"}
          </Button>
        </Box>
        <Divider />
        <Box p={2} mt={1}>
          {(data.comments || []).map((comment) => (
            <Comment key={comment.id} user={user} comment={comment} />
          ))}
        </Box>
      </Grid>
    </Grid>
  );
}

function LinkRouter(props) {
  return <Link {...props} component={RouterLink} />;
}
