export const esDict = {
  Email: "Correo Electrónico",
  "Phone Number": "Número de Teléfono",
  "Account recovery requires verified contact information":
    "La recuperación de la cuenta requiere información de contacto verificada",
  "Back to Sign In": "Volver a Iniciar Sesión",
  "Change Password": "Cambiar Contraseña",
  Changing: "Cambiando",
  Code: "Código",
  "Confirm Password": "Confirmar Contraseña",
  "Confirm Sign Up": "Confirmar Registro",
  "Confirm SMS Code": "Confirmar Código SMS",
  "Confirm TOTP Code": "Confirmar Código TOTP",
  Confirm: "Confirmar",
  "Confirmation Code": "Código de Confirmación",
  Confirming: "Confirmando",
  "Create a new account": "Crear una nueva cuenta",
  "Create Account": "Crear Cuenta",
  "Creating Account": "Creando Cuenta",
  "Dismiss alert": "Descartar alerta",
  "Enter your code": "Introduce tu código",
  "Enter your Password": "Introduce tu contraseña",
  "Enter your Email": "Introduce tu correo electrónico",
  "Enter your email": "Introduce tu correo electrónico",
  "Enter your phone number": "Introduce tu número de teléfono",
  "Enter your username": "Introduce tu nombre de usuario",
  "Forgot your password?": "¿Olvidaste tu contraseña?",
  "Hide password": "Ocultar contraseña",
  Loading: "Cargando",
  "New password": "Nueva contraseña",
  Password: "Contraseña",
  "Please confirm your Password": "Confirma tu contraseña",
  "Resend Code": "Reenviar Código",
  "Reset your password": "Restablecer tu contraseña",
  "Reset Password": "Restablecer Contraseña",
  "Reset your Password": "Restablecer tu Contraseña",
  "Send code": "Enviar código",
  "Send Code": "Enviar Código",
  Sending: "Enviando",
  "Setup TOTP": "Configurar TOTP",
  "Show password": "Mostrar contraseña",
  "Sign in to your account": "Iniciar sesión en tu cuenta",
  "Sign In with Amazon": "Iniciar sesión con Amazon",
  "Sign In with Apple": "Iniciar sesión con Apple",
  "Sign In with Facebook": "Iniciar sesión con Facebook",
  "Sign In with Google": "Iniciar sesión con Google",
  "Sign in": "Iniciar sesión",
  "Sign In": "Iniciar Sesión",
  "Signing in": "Iniciando sesión",
  Skip: "Omitir",
  Submit: "Enviar",
  Submitting: "Enviando",
  Username: "Nombre de usuario",
  "Verify Contact": "Verificar Contacto",
  Verify: "Verificar",
};
