import { API } from "aws-amplify";
import { useCallback, useState } from "react";
import { useAlertDispatch } from "../context/alert";

export default function usePayments() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { openErrorAlert } = useAlertDispatch();

  const createPayment = useCallback(async function createPayment(props) {
    try {
      setLoading(true);
      const response = await API.post(
        "lawyerapi",
        `/users/${props.userId}/payments`,
        {
          body: props,
        }
      );
      setData(props);
      console.log("Payment Response: ", response);
      return response.data;
    } catch (error) {
      console.error(error);
      setError(error);
      openErrorAlert(
        "Algo salió mal cargando el pago, por favor contacta soporte técnico."
      );
    } finally {
      setLoading(false);
    }
    //eslint-disable-next-line
  }, [])

  return [{ data, loading, error }, { createPayment }];
}
