import { Button, Container, Typography } from "@mui/material";
import React from "react";
import ZoomMtgEmbedded from "@zoomus/websdk/embedded";

const signatureEndpoint = "http://localhost:4001/users/zoom";
const sdkKey =
  process.env.ZOOM_SDK_KEY || "csACuGUQiUvefnmQ7jBUXv8jl8KjCOj7vPj6";

export default function MeetingPage({ user }) {
  const { username } = user;
  console.log("username :>> ", username);
  const client = ZoomMtgEmbedded.createClient();
  // const [accesToken, setAccessToken] = React.useState('')

  const meetingNumber = "123456789";
  const role = 0;
  const userName = "React";
  const userEmail = "andresfelipe9619@gmail.com";
  const passWord = "";

  // useEffect(() => {
  //   const init = async () => {
  //     const response = await fetch(signatureEndpoint + '-oauth', {
  //       method: 'POST'
  //     })
  //     const data = await response.json()
  //     setAccessToken(data)
  //   }
  //   init()
  // }, [])

  function getSignature(e) {
    e.preventDefault();

    fetch(signatureEndpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: role,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        startMeeting(response.signature);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // function getZAK (e) {
  //   e.preventDefault()

  //   fetch('https://api.zoom.us/v2/users/me/token?type=zak', {
  //     method: 'POST',
  //     headers: { Authorization: `Bearer ${accesToken}` }
  //   })
  //     .then(res => res.json())
  //     .then(response => {
  //       startMeeting(response.signature)
  //     })
  //     .catch(error => {
  //       console.error(error)
  //     })
  // }

  function startMeeting(signature) {
    let meetingSDKElement = document.getElementById("meetingSDKElement");
    client.init({
      debug: true,
      zoomAppRoot: meetingSDKElement,
      language: "en-US",
      customize: {
        meetingInfo: [
          "topic",
          "host",
          "mn",
          "pwd",
          "telPwd",
          "invite",
          "participant",
          "dc",
          "enctype",
        ],
        toolbar: {
          buttons: [
            {
              text: "Custom Button",
              className: "CustomButton",
              onClick: () => {
                console.log("custom button");
              },
            },
          ],
        },
      },
    });

    client.join({
      sdkKey: sdkKey,
      signature: signature,
      // meetingNumber: meetingNumber,
      password: passWord,
      userName: userName,
      userEmail: userEmail,
    });
  }

  return (
    <Container>
      <Typography variant="h2" paragraph>
        Sala de Reuniones
      </Typography>
      {/* For Component View */}
      <div id="meetingSDKElement">
        {/* Zoom Meeting SDK Component View Rendered Here */}
      </div>
      <Button variant="outlined" onClick={getSignature}>
        Join Meeting
      </Button>
      {/* <Button variant='outlined' onClick={getZAK}>
        Create Meeting
      </Button> */}
    </Container>
  );
}
