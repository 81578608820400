import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { LinearProgress, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Upload from "../form/Upload";

export default function AddDocument({
  open,
  uploading,
  categories,
  handleClose,
  handleConfirm,
}) {
  const [file, setFile] = useState(null);
  const [name, setName] = useState("");
  const [category, setCategory] = React.useState("");

  function handleChangeCategory(e) {
    setCategory(e.target.value);
  }

  function handleChangeFile(e) {
    setFile(e.target.value);
  }

  function handleChangeName(e) {
    setName(e.target.value);
  }

  const disabled = !category || !name || !file?.length;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ px: 4, py: 2 }}>
        Agregar Documento
      </DialogTitle>
      <DialogContent sx={{ p: 4 }}>
        {uploading && <LinearProgress />}
        <TextField
          sx={{ my: 2 }}
          fullWidth
          name="name"
          label="Nombre"
          disabled={uploading}
          value={name}
          onChange={handleChangeName}
        />
        <FormControl fullWidth sx={{ mb: 4 }} disabled={uploading}>
          <InputLabel id="category-select-label">Category</InputLabel>
          <Select
            labelId="category-select-label"
            id="category-select"
            value={category}
            label="Category"
            onChange={handleChangeCategory}
          >
            {categories.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Upload
          uploading={uploading}
          item={{ name: "document", label: "Document" }}
          handleChange={handleChangeFile}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={uploading} onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          disabled={uploading || disabled}
          onClick={async () => {
            await handleConfirm({ name, files: file, categoryId: category });
            handleClose();
          }}
          autoFocus
        >
          {uploading ? "Subiendo Documento..." : "Crear"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
