import { API } from "aws-amplify";
import { useCallback, useState } from "react";
import { useAlertDispatch } from "../context/alert";

export default function useNotes() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { openErrorAlert, openSuccessAlert } = useAlertDispatch();

  const deleteNote = useCallback(
    async function deleteNote(id) {
      try {
        setLoading(true);
        console.log("id", id);
        console.log("data", data);
        const found = data.findIndex((d) => d.id === id);
        console.log("found", found);
        if (found === -1) throw new Error("Note not found");
        const newData = data.slice();
        newData.splice(found, 1);
        const response = await API.del(
          "lawyerapi",
          `/processes/notes/${id}`,
          {}
        );
        console.log("Notes Response: ", response);
        setData(newData);
        openSuccessAlert(response.message);
      } catch (error) {
        console.error(error);
        setError(error);
        openErrorAlert("Algo salio mal");
      } finally {
        setLoading(false);
      }
    },
    //eslint-disable-next-line
    [data]
  );

  const updateNote = useCallback(async function updateNote(id, props) {
    try {
      setLoading(true);
      const response = await API.put("lawyerapi", `/processes/notes/${id}`, {
        body: props,
      });
      setData((prev) => ({ ...prev, ...props }));
      console.log("User Response: ", response);
      openSuccessAlert(response.message);
    } catch (error) {
      console.error(error);
      setError(error);
      openErrorAlert("Algo salio mal");
    } finally {
      setLoading(false);
    }
    //eslint-disable-next-line
  }, []);

  const createNote = useCallback(async function createNote(props) {
    try {
      setLoading(true);

      const response = await API.post("lawyerapi", `/processes/notes`, {
        body: props,
      });

      setData(response.data);
      console.log("Notes Response: ", response);
      openSuccessAlert(response.message);
    } catch (error) {
      console.error(error);
      setError(error);
      openErrorAlert("Algo salio mal");
    } finally {
      setLoading(false);
    }
    //eslint-disable-next-line
  }, []);

  const getNotes = useCallback(async function getNotes(processId) {
    try {
      setLoading(true);
      const response = await API.get(
        "lawyerapi",
        `/processes/${processId}/notes`
      );
      setData(response?.data || null);
      console.log("Notes Response: ", response?.data);
    } catch (error) {
      console.error(error);
      setError(error);
      openErrorAlert("Algo salio mal");
    } finally {
      setLoading(false);
    }
    //eslint-disable-next-line
  }, []);

  return [
    { data, loading, error },
    {
      getNotes,
      createNote,
      updateNote,
      deleteNote,
    },
  ];
}
