import { API } from "aws-amplify";
import { useCallback, useState } from "react";
import { useAlertDispatch } from "../context/alert";

export default function useProcesses() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { openErrorAlert, openSuccessAlert } = useAlertDispatch();

  const updateProcess = useCallback(async function updateProcess(id, props) {
    try {
      setLoading(true);
      const response = await API.put("lawyerapi", `/processes/${id}`, {
        body: props,
      });
      setData((prev) => ({ ...prev, ...props }));
      console.log("User Response: ", response);
      openSuccessAlert(response.message);
    } catch (error) {
      console.error(error);
      setError(error);
      openErrorAlert("Algo salio mal");
    } finally {
      setLoading(false);
    }
    //eslint-disable-next-line
  }, []);

  const deleteProcess = useCallback(
    async function deleteProcess(id) {
      try {
        setLoading(true);
        console.log("id", id);
        console.log("data", data);
        const found = data.findIndex((d) => d.id === id);
        console.log("found", found);
        if (found === -1) throw new Error("Process not found");
        const newData = data.slice();
        newData.splice(found, 1);
        const response = await API.del("lawyerapi", `/processes/${id}`);
        console.log("Document Response: ", response);
        setData(newData);
        openSuccessAlert(response.message);
      } catch (error) {
        console.error(error);
        setError(error);
        openErrorAlert("Algo salio mal");
      } finally {
        setLoading(false);
      }
    },
    //eslint-disable-next-line
    [data]
  );

  const createProcess = useCallback(async function createProcess(props) {
    try {
      setLoading(true);

      const response = await API.post("lawyerapi", `/processes`, {
        body: props,
      });

      setData((prev) => prev.concat(response.data));
      console.log("Document Response: ", response);
      openSuccessAlert(response.message);
    } catch (error) {
      console.error(error);
      setError(error);
      openErrorAlert("Algo salio mal");
    } finally {
      setLoading(false);
    }
    //eslint-disable-next-line
  }, []);

  const getProcesses = useCallback(async function getProcesses(query) {
    try {
      setLoading(true);
      const response = await API.get("lawyerapi", `/processes`, {
        queryStringParameters: query,
      });
      setData(response?.data || []);
      console.log("Processes Response: ", response?.data);
    } catch (error) {
      console.error(error);
      setError(error);
      openErrorAlert("Algo salio mal");
    } finally {
      setLoading(false);
    }
    //eslint-disable-next-line
  }, []);

  const getProcessDetail = useCallback(async function getProcessDetail(id) {
    try {
      setLoading(true);
      const response = await API.get("lawyerapi", `/processes/${id}`);
      setData(response?.data);
      console.log("Processes Response: ", response);
    } catch (error) {
      console.error(error);
      setError(error);
      openErrorAlert("Algo salio mal");
    } finally {
      setLoading(false);
    }
    //eslint-disable-next-line
  }, []);

  return [
    { data, loading, error },
    {
      updateProcess,
      getProcesses,
      getProcessDetail,
      createProcess,
      deleteProcess,
    },
  ];
}
