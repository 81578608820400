import format from "date-fns/format";
import { es } from "date-fns/locale";

export const isProduction = process.env.NODE_ENV === "production";

export const DATE_FORMAT = "yyyy-MM-dd HH:mm:ss";

export const SUPPORTED_IMAGE_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

export const WOMPI_PUBLIC_KEY = isProduction
  ? "pub_prod_eLY4ZQckgi28nDF0XjStrr8bjuRuHPmd"
  : "pub_test_E71BrEyowIPM0zJrDRAbrHT7Gcrmv11y";

export const WOMPI_ENDPOINT = `https://${
  isProduction ? "production" : "sandbox"
}.wompi.co/v1/transactions`;

export function splitArrayIntoChunksOfLen(arr, len) {
  var chunks = [],
    i = 0,
    n = arr.length;
  while (i < n) {
    chunks.push(arr.slice(i, (i += len)));
  }
  return chunks;
}

export const map2select = ([value, label]) => ({ label, value });

export function pluralize(word, count) {
  return `${word}${count === 1 ? "" : "s"}`;
}

export function dateFormatter({ value }) {
  if (value && new Date(value)) {
    return format(new Date(value), "PP", { locale: es });
  }
  return "N/A";
}

export function nullifyObjectEmptyStrings(object) {
  return Object.entries(object).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: replaceEmptyStringWithNull(value),
    }),
    {}
  );
}

export const getFileExtension = (fileName, forceLowerCase = true) => {
  let [extension] = fileName
    .split(".")
    .reverse()
    .slice(0, 1)
    .join("")
    .split("/");
  if (forceLowerCase) extension = extension.toLowerCase();
  return extension;
};

export const getFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () =>
      resolve({ name: file.name, base64: reader.result });
    reader.readAsDataURL(file);
  });
};

export const isLiked = (user, document) =>
  (user.likes || []).find((like) => like.documentId === document.id);

export const average = (data) =>
  Math.round(data.reduce((a, b) => a + b, 0) / data.length);

export function replaceEmptyStringWithNull(value) {
  if (!value) return null;
  return value;
}

export const isDate = (item) =>
  isNaN(item) && new Date(item) !== "Invalid Date" && !isNaN(new Date(item));

export const isObject = (item) => !!item && typeof item === "object";

export const isBoolean = (item) => typeof item === "boolean";

export const isNullish = (item) => typeof item === "undefined" || item === null;

export const PLANS = [
  {
    title: "Plan A",
    price: 10e4,
    limit: 100,
    description: ["1-100 procesos"],
  },
  {
    title: "Plan B",
    subheader: "Más Popular",
    price: 30e4,
    limit: 300,
    description: ["101-300 procesos"],
  },
  {
    title: "Plan C",
    price: 60e4,
    limit: 600,
    description: ["301-600 procesos"],
  },
];
