import React, { useEffect, useState } from "react";
import { Flex, Image } from "@aws-amplify/ui-react";
import Card from "@mui/material/Card";
import {
  Button,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import { COLOMBIAN_CODE, getInitialValues, validationSchema } from "./settings";
import useUserProfile from "../../hooks/useUserProfile";
import { uploadProfilePictureToS3 } from "../../utils/aws";
import {
  getFile,
  SUPPORTED_IMAGE_FORMATS,
  WOMPI_PUBLIC_KEY,
} from "../../utils";
import Plans from "../../components/payment/Plans";
import { citiesGroupByDepartment, departments } from "./cities";
import { useAlertDispatch } from "../../context/alert";

const IVA = 0.19;

function getPhoneNumber(profile) {
  const haveCode = profile?.phone.startsWith(COLOMBIAN_CODE);
  return haveCode ? profile?.phone : `${COLOMBIAN_CODE}${profile.phone}`;
}

function Profile({ user }) {
  const [avatar, setAvatar] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [{ data: profileData, loading }, { updateUser, getProfileData }] =
    useUserProfile();
  const { username: cognitoId, attributes } = user || {};
  const { email } = attributes;
  const { openErrorAlert } = useAlertDispatch();

  async function uploadPhoto() {
    try {
      let photo = profileData?.photo || "";
      if (!uploadedFile) return photo;

      const result = await uploadProfilePictureToS3(uploadedFile);
      console.log("result", result);
      photo = result.key;
      console.log("photo", photo);
      return photo;
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }

  async function onSubmit(values) {
    const profile = {
      ...values,
      email,
      cognitoId,
    };
    console.log("profile", profile);
    const phone = getPhoneNumber(profile);
    const photo = await uploadPhoto();
    const haveChanged = Object.entries(profileData).some(
      ([key, value]) => profile[key] !== value
    );
    if (haveChanged) {
      await updateUser(profileData.id, { ...profile, phone, photo });
    }
  }

  useEffect(() => {
    if (!cognitoId) return;
    getProfileData(cognitoId);
    //eslint-disable-next-line
  }, [cognitoId]);

  const formikProps = useFormik({
    onSubmit,
    validationSchema,
    initialValues: getInitialValues(profileData),
    enableReinitialize: true,
  });

  async function handleChangePhoto(event) {
    try {
      const [file] = event.currentTarget.files || [];
      console.log("file", file);
      if (!file) return;
      await formikProps.setFieldValue("photo", file);
      const { base64 } = await getFile(file);
      setAvatar(base64);
      setUploadedFile(file);
    } catch (error) {
      console.error(error);
      openErrorAlert(error);
    }
  }

  function generateReference(data) {
    const [first] = data.cognitoId.split("-");
    const today = new Date();
    const [month] = [today.getMonth() + 1];
    return `LW${month}${first}`;
  }

  function getPriceInfo(price) {
    const amount = price * 100;
    const tax = { type: "VAT", amount_in_cents: amount * IVA };
    return { amount, tax };
  }

  function handleSelectPlan(plan) {
    const [, phone] = profileData.phone.split(COLOMBIAN_CODE);
    const { amount, tax } = getPriceInfo(plan.price);
    // eslint-disable-next-line no-undef
    const checkout = new WidgetCheckout({
      currency: "COP",
      amountInCents: amount,
      reference: generateReference(profileData),
      publicKey: WOMPI_PUBLIC_KEY,
      redirectUrl: window.location.origin + "/payments",
      taxes: [tax],
      customerData: {
        email: profileData.email,
        fullName: profileData.name + " " + profileData.lastname,
        phoneNumber: phone,
        phoneNumberPrefix: COLOMBIAN_CODE,
        legalId: profileData.document,
        legalIdType: "CC",
      },
      shippingAddress: {
        addressLine1: profileData.address,
        city: profileData.city,
        phoneNumber: phone,
        region: profileData.region,
        country: "CO",
      },
    });

    checkout.open(function (result) {
      console.log("result :>> ", result);
      const transaction = result.transaction;
      console.log("Transaction ID: ", transaction.id);
      console.log("Transaction object: ", transaction);
    });
  }

  function handleChangeNotification(e) {
    const { checked } = e.target;
    formikProps.setFieldValue("enableNotifications", checked);
  }

  const src = avatar || profileData?.signedPhoto;
  const departmentCities = citiesGroupByDepartment[formikProps?.values?.region];

  return (
    <Grid container spacing={2}>
      {loading && <LinearProgress />}
      <Grid item xs={12} md={6}>
        <Card sx={{ px: 4, py: 2 }} elevation={3}>
          <CardContent>
            <Flex
              marginBottom={40}
              marginTop={40}
              alignItems={"center"}
              alignContent={"center"}
              justifyContent="center"
              direction={"column"}
            >
              {!!src && (
                <Image
                  alt="Foto Perfil"
                  src={src}
                  objectFit="initial"
                  objectPosition="50% 50%"
                  borderRadius="50%"
                  backgroundColor="initial"
                  height="auto"
                  width="180px"
                  opacity="100%"
                />
              )}
              <Flex mt={2}>
                <FormUpload
                  name="photo"
                  isSubmitting={formikProps.isSubmitting}
                  accept={SUPPORTED_IMAGE_FORMATS.join()}
                  handleChange={handleChangePhoto}
                />
              </Flex>
            </Flex>
            <Input
              name="name"
              required
              placeholder="Nombre"
              loading={loading}
              {...formikProps}
            />
            <Input
              name="lastname"
              required
              placeholder="Apellido"
              loading={loading}
              {...formikProps}
            />
            <Input
              name="phone"
              required
              placeholder="Celular"
              loading={loading}
              {...formikProps}
            />
            <FormGroup sx={{ justifySelf: "flex-end", my: 1 }}>
              <FormControlLabel
                control={
                  <Switch
                    isDisabled={formikProps.isSubmitting || loading}
                    checked={formikProps.values.enableNotifications}
                    onChange={handleChangeNotification}
                  />
                }
                label="Habilitar Notificaciones"
              />
            </FormGroup>
            <Divider />
            <Typography variant="h3" sx={{ mt: 4, mb: 2 }}>
              Información para pago
            </Typography>
            <Input
              name="document"
              placeholder="Documento"
              loading={loading}
              {...formikProps}
            />
            <FormSelect
              options={departments}
              label="Departamento"
              name={"region"}
              {...formikProps}
            />
            <FormSelect
              options={departmentCities}
              label="Ciudad"
              name={"city"}
              {...formikProps}
            />
            <Input
              name="address"
              placeholder="Dirección"
              loading={loading}
              {...formikProps}
            />
          </CardContent>
          <CardActions>
            <Button variant="outlined" onClick={formikProps.handleSubmit}>
              Guardar
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Plans profile={profileData} handleSelect={handleSelectPlan} />
    </Grid>
  );
}

function FormSelect({
  name,
  label,
  values,
  options,
  required = false,
  isSubmitting,
  handleChange,
  errors,
  touched,
}) {
  return (
    <FormControl
      sx={{ my: 1 }}
      fullWidth
      required={required}
      variant="outlined"
      error={!!errors[name]}
      disabled={isSubmitting}
    >
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        labelId={`${name}-label`}
        id={name}
        name={name}
        label={label}
        disabled={isSubmitting}
        value={values[name] || ""}
        onChange={handleChange}
      >
        {(options || []).map((d, i) => (
          <MenuItem key={i} value={d.value}>
            {d.label}
          </MenuItem>
        ))}
      </Select>
      {!!touched[name] && !!errors[name] && (
        <FormHelperText>{errors[name] || ""}</FormHelperText>
      )}
    </FormControl>
  );
}

const Input = ({
  name,
  required = true,
  placeholder,
  values,
  loading,
  isSubmitting,
  type = "text",
  handleChange,
  errors,
}) => (
  <TextField
    type={type}
    name={name}
    fullWidth
    sx={{ my: 1 }}
    required={required}
    disabled={isSubmitting || loading}
    value={values[name] || ""}
    placeholder={placeholder}
    onChange={handleChange}
    helperText={errors[name]}
    error={!!errors[name]}
  />
);

function FormUpload({ accept, name, label, handleChange, isSubmitting }) {
  return (
    <>
      <input
        hidden
        name={name}
        type="file"
        disabled={isSubmitting}
        id={`${name}-button-file`}
        accept={accept}
        onChange={handleChange}
      />
      <label htmlFor={`${name}-button-file`}>
        {label}
        <Button component="span" variant="outlined" disabled={isSubmitting}>
          Sube una foto
        </Button>
      </label>
    </>
  );
}

export default Profile;
